/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 *
 * Google CDN, Latest jQuery
 * To use the default WordPress version of jQuery, go to lib/config.php and
 * remove or comment out: add_theme_support('jquery-cdn');
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.


// jQuery for page scrolling feature - requires jQuery Easing plugin
$(function() {
    $('.navbar-nav li a').bind('click', function(event) {
        var $anchor = $(this);
        $('html, body').stop().animate({
            scrollTop: $($anchor.attr('href')).offset().top -100
        }, 1500, 'easeInOutExpo');
        event.preventDefault();
    });
});

// jQuery date picker
$(function() {
  $('#mikor1').datetimepicker({
    lang:'hu',
    formatDate:'Y-m-d',
    formatTime:'H:i',
    allowTimes:[
      '00:15', '00:30', '00:45', '01:00',
      '01:15', '01:30', '01:45', '02:00',
      '02:15', '02:30', '02:45', '03:00',
      '03:15', '03:30', '03:45', '04:00',
      '04:15', '04:30', '04:45', '05:00',
      '05:15', '05:30', '05:45', '06:00',
      '06:15', '06:30', '06:45', '07:00',
      '07:15', '07:30', '07:45', '08:00',
      '08:15', '08:30', '08:45', '09:00',
      '09:15', '09:30', '09:45', '10:00',
      '10:15', '10:30', '10:45', '11:00',
      '11:15', '11:30', '11:45', '12:00',
      '12:15', '12:30', '12:45', '13:00',
      '13:15', '13:30', '13:45', '14:00',
      '14:15', '14:30', '14:45', '15:00',
      '15:15', '15:30', '15:45', '16:00',
      '16:15', '16:30', '16:45', '17:00',
      '17:15', '17:30', '17:45', '18:00',
      '18:15', '18:30', '18:45', '19:00',
      '19:15', '19:30', '19:45', '20:00',
      '20:15', '20:30', '20:45', '21:00',
      '21:15', '21:30', '21:45', '22:00',
      '22:15', '22:30', '22:45', '23:00',
      '23:15', '23:30', '23:45', '00:00'
     ]
  });
  $('#mikor2').datetimepicker({
    lang:'hu',
    formatDate:'Y-m-d',
    formatTime:'H:i',
    allowTimes:[
      '00:15', '00:30', '00:45', '01:00',
      '01:15', '01:30', '01:45', '02:00',
      '02:15', '02:30', '02:45', '03:00',
      '03:15', '03:30', '03:45', '04:00',
      '04:15', '04:30', '04:45', '05:00',
      '05:15', '05:30', '05:45', '06:00',
      '06:15', '06:30', '06:45', '07:00',
      '07:15', '07:30', '07:45', '08:00',
      '08:15', '08:30', '08:45', '09:00',
      '09:15', '09:30', '09:45', '10:00',
      '10:15', '10:30', '10:45', '11:00',
      '11:15', '11:30', '11:45', '12:00',
      '12:15', '12:30', '12:45', '13:00',
      '13:15', '13:30', '13:45', '14:00',
      '14:15', '14:30', '14:45', '15:00',
      '15:15', '15:30', '15:45', '16:00',
      '16:15', '16:30', '16:45', '17:00',
      '17:15', '17:30', '17:45', '18:00',
      '18:15', '18:30', '18:45', '19:00',
      '19:15', '19:30', '19:45', '20:00',
      '20:15', '20:30', '20:45', '21:00',
      '21:15', '21:30', '21:45', '22:00',
      '22:15', '22:30', '22:45', '23:00',
      '23:15', '23:30', '23:45', '00:00'
     ]
  });
});

// Event tracking
$(function() {

  // Email
  $('#email').on('click', function() {
    ga('send', 'event', 'Link', 'click', 'Email');
  });

  // Skype
  $('#skype').on('click', function() {
    ga('send', 'event', 'Link', 'click', 'Skype');
  });

  // Viber
  $('#viber').on('click', function() {
    ga('send', 'event', 'Link', 'click', 'Viber');
  });

  // iMessage
  $('#imessage').on('click', function() {
    ga('send', 'event', 'Link', 'click', 'iMessage');
  });

  // Phone
  $('#call').on('click', function() {
    ga('send', 'event', 'Link', 'click', 'Telefonszám');
  });

  // Kalkulátor
  $('#szamol').on('click', function() {
    ga('send', 'event', 'Számol', 'click', 'Kalkulátor');
  });

  // Nemzetközi rendelés
  $('#megrendel').on('click', function() {
    ga('send', 'event', 'Rendelés', 'click', 'Nemzetközi rendelés');
  });
});
